<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>消息中心</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
<to-top></to-top>
  <div class="kbox"></div>
  <div class="hbox"></div>
  <div class="massegeBox">
     <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
    <van-list
          v-model:loading="options.loading"
          :finished="options.finished"
          :offset="600"
          finished-text="没有更多了"
          @load="onLoad"
        >
    <div class="message_1" v-for="m in options.data" :key="m" @click="read(m)">
      <div class="meL">
        <img v-if="m.source=='ORDER'" src="images/message1.png" />
        <img v-else-if="m.source=='LOGISTICS'" src="images/message2.png" />
        <img v-else-if="m.source=='POINTS'" src="images/message3.png" />
        <img v-else-if="m.source=='NOTICE'" src="images/message4.png" />
        <img v-else-if="m.source=='SNAP'" src="images/message1.png" />
        <img v-else-if="m.source=='CAPITAL'" src="images/message1.png" />
        <img v-else src="images/message1.png" />
      </div>
      <div class="meR" >
        <div class="meR_1">
          <p v-if="m.source=='ORDER'">订单通知</p>
          <p v-else-if="m.source=='LOGISTICS'">物流通知</p>
          <p v-else-if="m.source=='POINTS'">积分通知</p>
          <p v-else-if="m.source=='NOTICE'">系统通知</p>
          <p v-else-if="m.source=='SNAP'">连续拍</p>
          <p v-else-if="m.source=='CAPITAL'">资金中心</p>
          <p v-else>系统通知</p>
          <span>{{stringUtils.dateFormat(m.createTime,'YYYY-MM-DD HH:mm')}}</span>
        </div>
        <div class="meR_2" v-html="m.content"></div>
       
      </div>
       <div class="meB" v-if="m.isRead==0">未读</div>
    </div>
    </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive } from "vue";
import configUtils from "@/config/configUtils";
import memberModel from "@/model/memberModel";
import cmsApi from "@/api/cmsApi";
import { useStore } from "vuex";
import ToTop from '../../../components/ToTop.vue';
export default {
  components: { ToTop },
  setup() {
    const { isLogin } = memberModel();
    isLogin();
    const options = reactive({
      data: [],
      pageCount: 1,
      pageNo:1,
      refreshing:false,
      loading:false,
      finished:false,
      total:0
    });
    const params = reactive({
      draw: 1,
      start: 0,
      length: configUtils.page.size,
    });
    const store = useStore();
    async function onRefresh() {
        options.finished = false;
        options.loading = true;
        onLoad();
      }
      async function onLoad() {
        console.log("refreshing:" + options.refreshing);
        if (options.refreshing) {
          options.data = [];
          options.pageCount=1;
          options.pageNo=1;
          options.refreshing = false;
        }else{
          if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
        }
        params.start =
          (options.pageNo - 1) * params.length;
        params.start = params.start < 0 ? 0 : params.start;
       await cmsApi.messageSearch(params).then((res) => {
         if (res.status == 200) {
          options.total = res.recordsTotal;
           let count = Math.ceil(res.recordsTotal / params.length);
           options.pageCount = count;
           options.data = options.data.concat(res.data);
        }
        options.loading = false;
        console.log('data size:'+options.data.length);
        if (options.data.length >= options.total) {
          options.finished = true;
        }
       });
      }
    const read=(item)=>{
      cmsApi.messageRead({id:item.id}).then(res=>{
        if(res.status==200){
          var ss=res.result;
          store.commit('setMessageCount');
          // if(ss.doId!=null){
            store.commit('setNoticeMessage',{isNoticeShow:true,close:true,title:'消息',type:ss.source,message:ss.content,doId:ss.doId})
          // }
          onRefresh();
        }
      })
    }
    return {
      options,
      read,
      onRefresh,
      onLoad,
    };
  },
};
</script>

<style>
.message_1 .meB{
  position: relative;
  float:right;
  width: 2rem;
  line-height: .5rem;
  margin-right: -.2rem;
  color:#ff2150;
  margin-bottom: 2rem;
  z-index: 99;
}
</style>